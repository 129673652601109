import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo/black.svg'

const Header = () => {
    const [visible, setVisible] = useState(false);
    const handleScroll = () => {
        var scrollTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        if (scrollTop > 100) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <header className="site-header">
            <div id="header-wrap" className={`${visible ? 'fixed-header ' : ''}`}>
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-6 col-md-auto">
                            <a className="navbar-brand logo text-dark h2 mb-0" href="https://enviar.app">
                                <img src={logo} alt="enviar.app Logo" className="logo-img" />
                            </a>
                        </div>
                        <div className="col-6 col-md-auto text-end">
                            <a className="btn btn-primary" href="#contact">
                                Contacto
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
