import React from 'react';
import { Col } from 'reactstrap';
import Clientlogo from '../common/clientlogo';

const Herosection = () => {
    return (
        <section>
            <div className="row align-items-center">
                <Col className="col-12 col-lg-5 col-lg-6 order-lg-2 mb-8 mb-lg-0">
                    {/* Image */}
                    <img src={require(`../../assets/images/hero/01.png`)} className="img-fluid" alt="..." />
                </Col>
                <div className="col-12 col-lg-7 col-xl-6 order-lg-1">
                    {/* Heading */}
                    <h1 className="badge badge-primary-soft font-w-6 font-weight-bold">Envíos seguros y rápidos a todo México</h1>
                    <h2 className="display-4">
                        ¡Ahorra en envíos con <span className="text-primary">enviar.app</span>!
                    </h2>
                    {/* Text */}
                    <p className="lead text-muted">Optimiza tu logística con envíos flexibles y personalizados a medida de tus necesidades.</p>
                    {/* Buttons */}
                    <a href="#contact" className="btn btn-outline-primary">
                        Contáctanos
                    </a>
                    <Clientlogo logoitems={4} margintop={10} />
                </div>
            </div>
        </section>
    );
};

export default Herosection;
