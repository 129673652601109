import React from 'react';
import { Badge } from 'reactstrap';

const Work = () => {
    return (
        <section className="pt-0">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-lg-6 order-lg-2 mb-6 mb-lg-0">
                        <img src={require('../../assets/images/about/02.png')} alt="testimage" className="img-fluid" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-5 order-lg-1">
                        <div>
                            <h2 className="mt-3">Descuentos para empresas</h2>
                            <p className="lead mb-5  text-justify">
                                Obtén beneficios adicionales con nuestros descuentos especialmente pensados para empresas,
                                brindándote ahorros significativos y soluciones logísticas rentables.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Work;
