import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import './Vendor.js'
import Index from './pages/Index/index1';
import { Fragment } from 'react';
import "react-toastify/dist/ReactToastify.css";
import Scrolltop from './Layout/Header/BackToTop';


function App() {
  const scrollbarStyle = `
    ::-webkit-scrollbar {
      display: none;
    }
  `;

  return (
    <>

      <Fragment>

        <style>{scrollbarStyle}</style>
      
        {
          <div className="page-wrapper">
            <Header />
            <Routes>
              <Route exact path="/" element={<Index />} />
            </Routes>
            <Footer />
            <Scrolltop />
          </div>
        }
      </Fragment>
    </>
  );
}

export default App;
