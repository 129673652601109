import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import fedex from '../../assets/images/carriers/fedex.png';
import dhl from '../../assets/images/carriers/dhl.png';
import estafeta from '../../assets/images/carriers/estafeta.png';
import jyt from '../../assets/images/carriers/jyt.png';
import paquetexpress from '../../assets/images/carriers/paquetexpress.png';
// import redpack from '../../assets/images/carriers/redpack.png';


const Clientlogo = ({ logoitems, margintop }) => {
    return (
        <OwlCarousel
            className={`owl-carousel mt-${margintop} no-pb`}
            dotData={false}
            items={logoitems}
            autoplay={true}
            margin={30}
            dots={false}
            loop={true}
        >
            <div className="item">
                <div className="clients-logo">
                    <img className="img-fluid" src={fedex} alt="testimage" />
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                    <img className="img-fluid" src={dhl} alt="dhl-logo" />
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                    <img className="img-fluid" src={estafeta} alt="estafeta-logo" />
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                    <img className="img-fluid" src={jyt} alt="jyt-logo" />
                </div>
            </div>
            <div className="item">
                <div className="clients-logo">
                    <img className="img-fluid" src={paquetexpress} alt="testimage" />
                </div>
            </div>
        </OwlCarousel>
    );
};

export default Clientlogo;
