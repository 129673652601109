import React from 'react';
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'reactstrap';
import logo from '../../assets/images/logo/white.svg'

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer id="contact" className="py-11 bg-primary position-relative" data-bg-img="../../assets/images/bg/03.png">
            <div className="shape-1" style={{ height: '150px', overflow: 'hidden' }}>
                <svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{ height: '100%', width: '100%' }}>
                    <path d="M0.00,49.98 C150.00,150.00 271.49,-50.00 500.00,49.98 L500.00,0.00 L0.00,0.00 Z" style={{ stroke: 'none', fill: '#fff' }} />
                </svg>
            </div>
            <Container className="mt-11">
                <Row>
                    <div className="col-12 col-lg-5 col-xl-4 me-auto mb-6 mb-lg-0">
                        {/* <div className="subscribe-form bg-warning-soft p-5 rounded">
                            <h5 className="mb-4 text-white">Contáctanos</h5>
                            <form id="mc-form" className="group" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="mc-nombre" className="text-light">Nombre</label>
                                    <input
                                        type="text"
                                        name="nombre"
                                        className="form-control"
                                        id="mc-nombre"
                                        placeholder="Benito Pablo Juárez García"
                                        required
                                        style={{ height: '60px' }}
                                        value={formData.nombre}
                                        onChange={handleChange}
                                    />
                                    {errors.nombre && <span className="text-danger">Este campo es obligatorio.</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mc-email" className="text-light">Correo</label>
                                    <input
                                        type="email"
                                        name="correo"
                                        className="email form-control"
                                        id="mc-email"
                                        placeholder="ejemplo@correo.com"
                                        required
                                        style={{ height: '60px' }}
                                        value={formData.correo}
                                        onChange={handleChange}
                                    />
                                    {errors.correo && <span className="text-danger">Correo inválido.</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mc-whatsapp" className="text-light">WhatsApp</label>
                                    <input
                                        type="text"
                                        name="whatsapp"
                                        className="form-control"
                                        id="mc-whatsapp"
                                        placeholder="8111111111"
                                        required
                                        style={{ height: '60px' }}
                                        value={formData.whatsapp}
                                        onChange={handleChange}
                                    />
                                    {errors.whatsapp && <span className="text-danger">Número de WhatsApp inválido.</span>}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mc-cantidad" className="text-light">Cantidad de envíos mensual</label>
                                    <input
                                        type="text"
                                        name="cantidad"
                                        className="form-control"
                                        id="mc-cantidad"
                                        placeholder="100"
                                        required
                                        style={{ height: '60px' }}
                                        value={formData.cantidad}
                                        onChange={handleChange}
                                    />
                                    {errors.cantidad && <span className="text-danger">Cantidad inválida.</span>}
                                </div>
                                <input
                                    className="btn btn-outline-light btn-block mt-3 mb-2"
                                    type="submit"
                                    name="subscribe"
                                    value="Subscribe"
                                />
                            </form>
                        </div> */}
                        <iframe src="https://whatsform.com/td8j5E" width="100%" height="800" />
                    </div>
                    <div className="col-12 col-lg-6 col-xl-7">
                        {/* <Row>
                            <Col className="col-12 col-sm-4 navbar-dark">
                                <h5 className="mb-4 text-white">Pages</h5>
                                <ul className="navbar-nav list-unstyled mb-0">
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/about-us">About</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/product-grid">Shop</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/faq">Faq</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/blog-card">Blogs</Link>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/contact-us">Contact Us</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                                <h5 className="mb-4 text-white">Service</h5>
                                <ul className="navbar-nav list-unstyled mb-0">
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="#">Content Writing</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="#">Documentation</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/sign-in">Account</Link>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="/career">Careers</Link>
                                    </li>
                                </ul>
                            </Col>
                            <Col className="col-12 col-sm-4 mt-6 mt-sm-0 navbar-dark">
                                <h5 className="mb-4 text-white">Legal</h5>
                                <ul className="navbar-nav list-unstyled mb-0">
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/term-condition">Term Of Service</Link>
                                    </li>
                                    <li className="mb-3 nav-item"><Link className="nav-link" to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li className="nav-item"><Link className="nav-link" to="#">Support</Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row> */}
                        <Row className="mt-5">
                            <Col className="col-12 d-flex align-items-center justify-content-between flex-wrap">
                                <a href="https://enviar.app" className="me-3 mb-2 mb-md-0">
                                    <img src={logo} alt="enviar.app Logo" className="logo-img" />
                                </a>
                                <ul className="list-inline mb-0 d-flex flex-wrap">
                                    <li className="list-inline-item me-2 mb-2 mb-md-0">
                                        <Link className="text-light ic-2x" to="https://www.facebook.com/profile.php?id=61559288626695&mibextid=ZbWKwL">
                                            <i className="la la-facebook" />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item me-2 mb-2 mb-md-0">
                                        <Link className="text-light ic-2x" to="https://www.instagram.com/enviar.app?igsh=NzE1OThzcnJyeGUx">
                                            <i className="la la-instagram" />
                                        </Link>
                                    </li>
                                    {/* Descomenta si necesitas más enlaces
                                        <li className="list-inline-item me-2 mb-2 mb-md-0">
                                        <Link className="text-light ic-2x" to="#"><i className="la la-twitter" /></Link>
                                        </li>
                                        <li className="list-inline-item me-2 mb-2 mb-md-0">
                                        <Link className="text-light ic-2x" to="#"><i className="la la-linkedin" /></Link>
                                        </li>
                                  */}
                                </ul>
                            </Col>
                            <p className="text-light text-justify">
                                En enviar.app ofrecemos soluciones a través de una plataforma de envíos,
                                comprometidos con la excelencia e innovación para satisfacer a nuestros clientes
                                y simplificar sus necesidades de envío. Nos dedicamos a brindar un servicio de alta calidad,
                                adaptado a las demandas del mercado y enfocado en la eficiencia y la rapidez.
                                Nuestro objetivo es hacer que tus envíos sean más fáciles y económicos,
                                proporcionándote herramientas avanzadas y un soporte excepcional en todo momento. Únete a nosotros
                                y descubre cómo podemos optimizar tu logística con nuestras soluciones innovadoras.
                            </p>
                        </Row>
                    </div>
                </Row>
                <Row className="text-white text-center mt-8">
                    <Col>
                        <hr className="mb-8" />© Copyright {year} enviar.app
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
